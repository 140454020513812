import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { icons } from '../../../assets/images'
import ButtonBase from '../../../components/partials/Button'
import { InitialStateType } from '../../report'
import DateTimePicker from '../../../components/date-picker'
import { FormInput } from '../../../components/partials/formInput'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { commissionPercentPatternNumber, namePattern } from '../../../utils/regex'
import { useMutation } from 'react-query'
import { editCarrierCommission } from '../../../services/commissionService'
import { notify } from '../../../components/commons/ToastNotify'

const CarrierEdit = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  const [requestFrom, setRequestFrom] = useState<InitialStateType>({
    date: undefined as any,
    identify: 'from',
  })
  const [requestTo, setRequestTo] = useState<InitialStateType>({
    date: undefined as any,
    identify: 'to',
  })
  const [isDisable, setIsDisable] = useState<boolean>(true)
  const [isValidationDefaultValue, setIsValidationDefaultValue] = useState<boolean>(false)

  // handle submit filter
  const onSetCallBackDateTime = (value: InitialStateType, identify: string) => {
    if (value !== null && value !== undefined) {
      if (identify === 'from') {
        setRequestFrom(value)
      } else {
        setRequestTo(value)
      }
    }
  }

  const { mutate: editCarrierCommissionById } = useMutation(
    async () => {
      const payload = {
        carrierKey: getValues('carrierKey'),
        carrierName: getValues('carrierName'),
        subclassBusinessType: {
          // subclassType: 'carrier',
          instantIssuePercent: getValues('instantIssuePercent')
            ? parseFloat(Number(getValues('instantIssuePercent')).toFixed(2))
            : null,
          instantIssueText: getValues('instantIssueText')
            ? getValues('instantIssueText').trim()
            : null,
          standardPercent: getValues('standardPercent')
            ? parseFloat(Number(getValues('standardPercent')).toFixed(2))
            : null,
          standardText: getValues('standardText') ? getValues('standardText').trim() : null,
          otherPercent: getValues('otherPercent')
            ? parseFloat(Number(getValues('otherPercent')).toFixed(2))
            : null,
          otherText: getValues('otherText') ? getValues('otherText').trim() : null,
          contractPercent: getValues('contractPercent')
            ? parseFloat(Number(getValues('contractPercent')).toFixed(2))
            : null,
          contractText: getValues('contractText') ? getValues('contractText').trim() : null,
          accountContractPercent: getValues('accountContractPercent')
            ? parseFloat(Number(getValues('accountContractPercent')).toFixed(2))
            : null,
          accountContractText: getValues('accountContractText')
            ? getValues('accountContractText').trim()
            : null,
          accountCommercialPercent: getValues('accountCommercialPercent')
            ? parseFloat(Number(getValues('accountCommercialPercent')).toFixed(2))
            : null,
          accountCommercialText: getValues('accountCommercialText')
            ? getValues('accountCommercialText').trim()
            : null,
        },
      }
      return editCarrierCommission(payload, state?.data?.carrierCommissionId)
    },
    {
      onSuccess: (res) => {
        // handle when success
        notify(MESSAGES.SUCCESS.S_CARRIER_COMMISSION_DETAIL_EDIT, STATUS.SUCCESS)
        navigate(-1)
      },
      onError: (err: Error) => {
        // handle when success
        notify(MESSAGES.ERROR.E_SOMETHING_WENT_WRONG, STATUS.ERROR)
      },
    },
  )

  // handle submit form
  const onSubmit = (data: any) => {
    delete data?.carrierName
    let checkVal = 0

    for (const key of Object.keys(data)) {
      if (data[key].toString().toLocaleLowerCase() === 'default') {
        checkVal += 1
      }
    }
    if (checkVal !== 1) {
      setIsValidationDefaultValue(true)
    } else {
      editCarrierCommissionById()
    }
  }

  // handle check required
  const handleCheckRequired = (val: string) => {
    if (val !== '') {
      return true
    } else {
      return false
    }
  }

  const handleCheckOnlySpaces = (val: string, valValidation: string) => {
    if (!valValidation) {
      return true
    } else {
      if (val.toString().trim() === '') {
        return false
      }
    }
  }

  useEffect(() => {
    if (requestFrom.date && requestTo.date) {
      setIsDisable(true)
    } else {
      setIsDisable(false)
    }
  }, [requestFrom.date, requestTo.date])

  // set default value
  useEffect(() => {
    const objData = state?.data
    setValue('carrierName', objData?.carrierName !== null ? objData?.carrierName : '')
    setValue('carrierKey', objData?.carrierKey !== null ? objData?.carrierKey : '')
    setValue(
      'instantIssuePercent',
      objData?.instantIssuePercent !== null ? objData?.instantIssuePercent : '',
    )
    setValue(
      'instantIssueText',
      objData?.instantIssueText !== null ? objData?.instantIssueText : '',
    )
    setValue('standardPercent', objData?.standardPercent !== null ? objData?.standardPercent : '')
    setValue('standardText', objData?.standardText !== null ? objData?.standardText : '')
    setValue('otherPercent', objData?.otherPercent !== null ? objData?.otherPercent : '')
    setValue('otherText', objData?.otherText !== null ? objData?.otherText : '')
    setValue('contractPercent', objData?.contractPercent !== null ? objData?.contractPercent : '')
    setValue('contractText', objData?.contractText !== null ? objData?.contractText : '')
    setValue(
      'accountCommercialPercent',
      objData?.accountCommercialPercent !== null ? objData?.accountCommercialPercent : '',
    )
    setValue(
      'accountCommercialText',
      objData?.accountCommercialText !== null ? objData?.accountCommercialText : '',
    )
    setValue(
      'accountContractPercent',
      objData?.accountContractPercent !== null ? objData?.accountContractPercent : '',
    )
    setValue(
      'accountContractText',
      objData?.accountContractText !== null ? objData?.accountContractText : '',
    )
  }, [])

  // render input & check validation
  const renderInput = (
    fieldInput: string,
    fieldValidation: string,
    defaultValue: string,
    isPercentage: boolean,
  ) => {
    const errorClass = `mt-[2px] -mb-[2px] text-body3 text-indicator-red`
    return (
      <div className="relative">
        <input
          type="text"
          {...register(fieldInput, {
            required: handleCheckRequired(watch(fieldValidation)),
            min: isPercentage ? 0 : undefined,
            max: isPercentage ? 100 : undefined,
            pattern: isPercentage ? commissionPercentPatternNumber : undefined,
            validate: (value) => handleCheckOnlySpaces(value, watch(fieldValidation)),
          })}
          className={`outline-none w-full text-body2 font-semibold ${
            errors[fieldInput]?.type
              ? 'bg-neutral-8 border-b-[1px] !border-indicator-red'
              : 'bg-neutral-8 border-b-[1px] border-neutral-5'
          }`}
          defaultValue={defaultValue}
        />
        {isPercentage && <p className="absolute top-0 right-2 text-neutral-4">%</p>}
        {errors[fieldInput]?.type === 'required' ? (
          <p className={errorClass}>The inputs are not matched.</p>
        ) : errors[fieldInput]?.type === 'pattern' ? (
          <p className={errorClass}>Please fill in a percentage.</p>
        ) : errors[fieldInput]?.type === 'max' ? (
          <p className={errorClass}>Can’t be larger than 100%.</p>
        ) : errors[fieldInput]?.type === 'min' ? (
          <p className={errorClass}>Can’t be smaller than 0%.</p>
        ) : errors[fieldInput]?.type === 'validate' ? (
          <p className={errorClass}>Can’t contain the space characters only.</p>
        ) : (
          <div className="h-4" />
        )}
      </div>
    )
  }

  return (
    <div className="my-10">
      <div className="flex">
        <img
          className="cursor-pointer"
          src={icons.iconBack}
          alt="back"
          onClick={() => navigate(-1)}
        />
        <p className="text-headline4 ml-8 mt-[2px]">Edit carrier</p>
      </div>
      <div className="mt-10 mb-4">
        <div className="flex justify-between">
          <p className="pt-3 font-semibold min-w-[280px]">
            Carrier name <span className="text-indicator-red">*</span>
          </p>
          <div className="w-[83%] min-w-[700px]">
            <FormInput
              id="carrierName"
              type="text"
              name="carrierName"
              label="Name"
              placeholder="Enter Carrier name"
              className="col-span-3"
              inputClassName="w-full"
              register={register}
              maxLength={200}
              rules={{
                required: MESSAGES.ERROR.E_REQUIRED,
                pattern: namePattern,
              }}
              errors={errors}
            />
          </div>
        </div>
        <div className="flex justify-between mt-6">
          <p className="pt-3 font-semibold min-w-[280px]">
            Carrier key <span className="text-indicator-red">*</span>
          </p>
          <div className="w-[83%] min-w-[700px]">
            <FormInput
              id="carrierKey"
              type="text"
              name="carrierKey"
              label="Name"
              placeholder="Enter Carrier key"
              className="col-span-3"
              inputClassName="w-full"
              register={register}
              rules={{
                required: MESSAGES.ERROR.E_REQUIRED,
              }}
              errors={errors}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between w-full pt-5 pb-6">
        <p className="font-semibold min-w-[280px]">Categorizing String - Date From</p>
        <div className="w-[83%] min-w-[980px] flex">
          <DateTimePicker
            setCallBackDateTime={onSetCallBackDateTime}
            identify={'from'}
            requestDateTime={requestFrom}
            setDateTime={setRequestFrom}
            // minDate={LimitSelectToDate()}
            // maxDate={!requestTo?.date ? new Date() : requestTo?.date}
            styles="mr-6"
          />
          <p className="mt-4 mr-6 font-semibold">To</p>
          <DateTimePicker
            setCallBackDateTime={onSetCallBackDateTime}
            identify={'to'}
            requestDateTime={requestTo}
            setDateTime={setRequestTo}
            // minDate={requestFrom?.date ? requestFrom?.date : undefined}
            // maxDate={new Date()}
            styles="mr-6"
          />
          <ButtonBase disabled={!isDisable} className="btn-outline" onClick={() => null}>
            Apply
          </ButtonBase>
        </div>
      </div>
      <div className="relative w-full overflow-auto border-b-2 border-x-2 rounded-t-2xl rounded-b-2xl min-w-[980px]">
        <table className="w-full text-left border-t-2">
          <thead className="bg-primary-shade4 text-body1 text-neutral-2 md:pr-4">
            <tr className="">
              <th className="px-6 py-5 w-[22%]">Business type</th>
              <th className="py-5 w-[18%]">Commission Percent</th>
              <th className="py-5 w-[48%] pl-4">Categorizing Strings</th>
              <th className="py-5"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b-[1px] border-neutral-6 h-[60px]">
              <td className="px-6 py-5 w-[20%]">Instant Issue</td>
              <td className="pb-[6px] pt-[18px] w-[18%]">
                {renderInput(
                  'instantIssuePercent',
                  'instantIssueText',
                  watch('instantIssuePercent'),
                  true,
                )}
              </td>
              <td className="pb-[6px] pt-[18px] w-[48%] pl-4">
                {renderInput(
                  'instantIssueText',
                  'instantIssuePercent',
                  watch('instantIssueText'),
                  false,
                )}
              </td>
              <td></td>
            </tr>
            <tr className="border-b-[1px] border-neutral-6">
              <td className="px-6 py-5 w-[22%]">Standard</td>
              <td className="pb-[6px] pt-[18px] w-[18%]">
                {renderInput('standardPercent', 'standardText', watch('standardPercent'), true)}
              </td>
              <td className="pb-[6px] pt-[18px] w-[48%] pl-4">
                {renderInput('standardText', 'standardPercent', watch('standardText'), false)}
              </td>
              <td></td>
            </tr>
            <tr className="border-b-[1px] border-neutral-6">
              <td className="px-6 py-5 w-[22%]">Other</td>
              <td className="pb-[6px] pt-[18px] w-[18%]">
                {renderInput('otherPercent', 'otherText', watch('otherPercent'), true)}
              </td>
              <td className="pb-[6px] pt-[18px] w-[48%] pl-4">
                {renderInput('otherText', 'otherPercent', watch('otherText'), false)}
              </td>
              <td></td>
            </tr>
            <tr className="border-b-[1px] border-neutral-6">
              <td className="px-6 py-5 w-[22%]">Contract (Fast Pass)</td>
              <td className="pb-[6px] pt-[18px] w-[18%]">
                {renderInput('contractPercent', 'contractText', watch('contractPercent'), true)}
              </td>
              <td className="pb-[6px] pt-[18px] w-[48%] pl-4">
                {renderInput('contractText', 'contractPercent', watch('contractText'), false)}
              </td>
              <td className="flex justify-center mt-[10px]">
                <ButtonBase className="btn-outline w-[80px] h-10" onClick={() => null}>
                  Test
                </ButtonBase>
              </td>
            </tr>
            <tr className="border-b-[1px] border-neutral-6">
              <td className="px-6 py-5 w-[22%]">Account (Commercial)</td>
              <td className="pb-[6px] pt-[18px] w-[18%]">
                {renderInput(
                  'accountCommercialPercent',
                  'accountCommercialText',
                  watch('accountCommercialPercent'),
                  true,
                )}
              </td>
              <td className="pb-[6px] pt-[18px] w-[48%] pl-4">
                {renderInput(
                  'accountCommercialText',
                  'accountCommercialPercent',
                  watch('accountCommercialText'),
                  false,
                )}
              </td>
              <td className="flex justify-center mt-[10px]">
                <ButtonBase className="btn-outline w-[80px] h-10" onClick={() => null}>
                  Test
                </ButtonBase>
              </td>
            </tr>
            <tr className="border-b-[1px] border-neutral-6">
              <td className="px-6 py-5 w-[22%]">Account (Contract)</td>
              <td className="pb-[6px] pt-[18px] w-[18%]">
                {renderInput(
                  'accountContractPercent',
                  'accountContractText',
                  watch('accountContractPercent'),
                  true,
                )}
              </td>
              <td className="pb-[6px] pt-[18px] w-[48%] pl-4">
                {renderInput(
                  'accountContractText',
                  'accountContractPercent',
                  watch('accountContractText'),
                  false,
                )}
              </td>
              <td className="flex justify-center mt-[10px]">
                <ButtonBase className="btn-outline w-[80px] h-10" onClick={() => null}>
                  Test
                </ButtonBase>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {isValidationDefaultValue && (
        <div className="flex p-3 border-l-4 mt-7 bg-indicator-red2 border-indicator-red">
          <div className="w-2 h-2 mt-[6px] mr-5 ml-2 rounded bg-indicator-red" />
          <p className="text-body2 text-indicator-red">
            Must have only Default value for Categorizing Strings
          </p>
        </div>
      )}
      <div className="flex justify-end mt-7">
        <ButtonBase className="mr-3 btn-outline w-[220px]" onClick={() => navigate(-1)}>
          Cancel
        </ButtonBase>
        <ButtonBase className="btn-outline mr-3 w-[220px]" onClick={() => null}>
          Test for overlap
        </ButtonBase>
        <ButtonBase className="btn-primary w-[220px]" onClick={handleSubmit(onSubmit)}>
          Save
        </ButtonBase>
      </div>
    </div>
  )
}

export default CarrierEdit

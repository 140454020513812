import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { AnyAction } from 'redux'
import {
  SET_OPEN_POPUP_CONFIRM_CREATE_CARRIER_COMMISSION,
  SET_CLOSE_POPUP_CONFIRM_CREATE_CARRIER_COMMISSION,
  SET_COUNT_NEW_CARRIER_COMMISSION,
  CHECK_COUNT_NEW_CARRIER_COMMISSION,
} from '../actions/actionTypes'

type activitiesState = {
  isPopupAddNewCarrierIsOpen: boolean
  countCarrierCommission: boolean
}

const initialState: activitiesState = {
  isPopupAddNewCarrierIsOpen: false,
  countCarrierCommission: false,
}
/**
 *
 * @param payload
 */
const reportReducer = (state = initialState, action: AnyAction) => {
  const { payload, type } = action
  if (state === undefined) {
    return initialState
  }
  switch (type) {
    case SET_OPEN_POPUP_CONFIRM_CREATE_CARRIER_COMMISSION:
      return {
        ...state,
        isPopupAddNewCarrierIsOpen: true,
      }
    case SET_CLOSE_POPUP_CONFIRM_CREATE_CARRIER_COMMISSION:
      return {
        ...state,
        isPopupAddNewCarrierIsOpen: false,
      }
    case SET_COUNT_NEW_CARRIER_COMMISSION:
      return {
        ...state,
        countCarrierCommission: true,
      }
    case CHECK_COUNT_NEW_CARRIER_COMMISSION:
      return {
        ...state,
        countCarrierCommission: false,
      }
    default:
      return state
  }
}

const persistConfig = {
  key: 'report',
  storage: storage,
  whiteList: ['isPopupAddNewCarrierIsOpen'],
}

export default persistReducer(persistConfig, reportReducer)

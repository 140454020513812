import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'

import reportWebVitals from './reportWebVitals'
import RouterOutlet from './configs/router/RouterOutlet'
import { store } from './store'
import { DialogProvider } from './components/commons/Dialog'
import 'tippy.js/dist/tippy.css'
import './assets/stylesheet/styles.scss'
import ToastNotify from './components/commons/ToastNotify'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
})

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <DialogProvider>
          <ToastNotify />
          <RouterOutlet />
        </DialogProvider>
      </Provider>
    </QueryClientProvider>
  </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

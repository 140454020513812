import iconInfoError from './info-error.svg'
import iconInfoDark from './info-dark.svg'
import iconArrowTop from './arrow-top-icon.svg'
import iconArrowRight from './arrow-right-icon.svg'
import iconArrowLeft from './arrow-left-icon.svg'
import iconArrowBottom from './arrow-bottom-icon.svg'
import iconUpload from './icon-upload.svg'
import iconBack from './icon-back.svg'
import iconPenEdit from './icon-pen-edit.svg'
import noData from './no-data.svg'

export const icons = {
  iconInfoError,
  iconInfoDark,
  iconArrowTop,
  iconArrowRight,
  iconArrowLeft,
  iconArrowBottom,
  iconUpload,
  iconBack,
  iconPenEdit,
  noData,
}

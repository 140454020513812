import React, { FC } from 'react'
import classNames from 'classnames'

export type FormErrorMessageProps = {
  className?: string
  children?: React.ReactNode
}

export const FormErrorMessage: FC<FormErrorMessageProps> = ({ children, className }) => (
  <p className={classNames('text-sm text-left block text-red-700', className)}>{children}</p>
)

import { useQuery } from 'react-query'
import { BASE_URL } from '../constants/urls'
import axiosService from './axiosServices'
import { API_ENDPOINTS } from './urlApi'

export const getAllFileReportData = (reqParams: object) => {
  const url = BASE_URL + API_ENDPOINTS.report.getFileReportData
  return useQuery(['report'], async () => {
    const { data }: any = await axiosService.getAll(reqParams, url)
    return data
  })
}

export const getAllFileReport = async (reqParams: object) => {
  const url = BASE_URL + API_ENDPOINTS.report.getFileReportData
  const { data }: any = await axiosService.getAll(reqParams, url)
  return data
}

export const uploadRecord = async (dataReq: object) => {
  const url = BASE_URL + API_ENDPOINTS.upload.uploadRecord
  return await axiosService.post(JSON.stringify(dataReq) as any, url)
}

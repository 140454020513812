import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { icons } from '../../../assets/images'
import ButtonBase from '../../../components/partials/Button'
import { ReactComponent as IconPenEdit } from '../../../assets/images/icon-pen-edit.svg'
import { notify } from '../../../components/commons/ToastNotify'
import { STATUS } from '../../../models/common'
import { getCommissionById } from '../../../services/commissionService'
import { MESSAGES } from '../../../constants/messages'
import Loading from '../../../components/loading'
import { renderPercentage } from '../../../helpers/convertPercent'

const CarrierDetail = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  // call api
  const { data, isLoading, isError } = getCommissionById(state.data)

  useEffect(() => {
    if (isError) {
      notify(MESSAGES.ERROR.E_SOMETHING_WENT_WRONG, STATUS.ERROR)
    }
  }, [isError])

  const renderCategorizingStrings = (val: string) => {
    if (val) {
      return `${val}`
    } else {
      return `N/A`
    }
  }

  if (isLoading) {
    return <Loading height={50} width={50} />
  }

  return (
    <div className="my-10">
      <div className="flex">
        <img
          className="cursor-pointer"
          src={icons.iconBack}
          alt="back"
          onClick={() => navigate('/commission')}
        />
        <p className="text-headline4 ml-8 mt-[2px]">Carrier detail</p>
      </div>
      <div className="flex justify-between my-5 min-w-[980px]">
        <p className="text-headline5A mt-[13px]">{data?.carrierName} details</p>
        <ButtonBase
          className="btn-outline w-[118px] h-[52px]"
          onClick={() =>
            navigate(`/commission/carrier/${state.data}/edit`, { state: { data: data } })
          }
        >
          <IconPenEdit />
          <p className="ml-3">Edit</p>
        </ButtonBase>
      </div>
      <div className="relative w-full overflow-auto border-b-2 border-x-2 rounded-t-2xl rounded-b-2xl min-w-[980px]">
        <table className="w-full text-left border-t-2">
          <thead className="bg-primary-shade4 text-body1 text-neutral-2 md:pr-4">
            <tr className="">
              <th className="px-6 py-5 w-[280px]">Business type</th>
              <th className="py-5 w-[220px]">Commission Percent</th>
              <th className="py-5">Categorizing Strings</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b-[1px] border-neutral-6">
              <td className="px-6 py-5 w-[280px]">Instant Issue</td>
              <td className="py-5 w-[220px]">{renderPercentage(data?.instantIssuePercent)}</td>
              <td className="py-5">{renderCategorizingStrings(data?.instantIssueText)}</td>
            </tr>
            <tr className="border-b-[1px] border-neutral-6">
              <td className="px-6 py-5 w-[280px]">Standard</td>
              <td className="py-5 w-[220px]">{renderPercentage(data?.standardPercent)}</td>
              <td className="py-5">{renderCategorizingStrings(data?.standardText)}</td>
            </tr>
            <tr className="border-b-[1px] border-neutral-6">
              <td className="px-6 py-5 w-[280px]">Other</td>
              <td className="py-5 w-[220px]">{renderPercentage(data?.otherPercent)}</td>
              <td className="py-5">{renderCategorizingStrings(data?.otherText)}</td>
            </tr>
            <tr className="border-b-[1px] border-neutral-6">
              <td className="px-6 py-5 w-[280px]">Contract (Fast Pass)</td>
              <td className="py-5 w-[220px]">{renderPercentage(data?.contractPercent)}</td>
              <td className="py-5">{renderCategorizingStrings(data?.contractText)}</td>
            </tr>
            <tr className="border-b-[1px] border-neutral-6">
              <td className="px-6 py-5 w-[280px]">Account (Commercial)</td>
              <td className="py-5 w-[220px]">{renderPercentage(data?.accountCommercialPercent)}</td>
              <td className="py-5">{renderCategorizingStrings(data?.accountCommercialText)}</td>
            </tr>
            <tr className="border-b-[1px] border-neutral-6">
              <td className="px-6 py-5 w-[280px]">Account (Contract)</td>
              <td className="py-5 w-[220px]">{renderPercentage(data?.accountContractPercent)}</td>
              <td className="py-5">{renderCategorizingStrings(data?.accountContractText)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CarrierDetail

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Line } from 'rc-progress'
import axios from 'axios'
import Moment from 'react-moment'
import Upload from 'rc-upload'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery } from 'react-query'

import { tableHeader } from './table/data'
import { getAllFileReport } from '../../services/fetchReportMng'
import { Header } from './component/header'
import { BASE_URL } from '../../constants/urls'
import { API_ENDPOINTS } from '../../services'
import { notify } from '../../components/commons/ToastNotify'
import { MESSAGES, STATUS } from '../../constants/messages'
import { MESSAGE_RESPONSE, STATUS_CODE } from '../../constants/statusCode'
import ButtonBase from '../../components/partials/Button'
import { ReactComponent as IconUpload } from '../../assets/images/icon-upload.svg'
import Loading from '../../components/loading'
import DateTimePicker from '../../components/date-picker'
import '../../assets/css/datePicker.css'
import '../../assets/css/scollbar.css'
import { LIMIT_PAGE } from '../../constants/common'
import PopupBase from '../../components/commons/Popup'
import { HandleChangeString } from '../../helpers/functionUtils'
import { ReactComponent as IconCancel } from '../../assets/images/icon-cancel.svg'
import {
  checkCountCarrierCommission,
  setCloseConfirmCreateCarrierCommission,
  setCountCarrierCommission,
  setOpenConfirmCreateCarrierCommission,
} from '../../redux/actions/fileReportMng'

export type InitialStateType = {
  date: Date
  identify?: string
}
export const initialState: InitialStateType = {
  date: undefined as any,
}

const ReportManagement = () => {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
  const [takePage, setTakePage] = useState<number>(1)
  const [isStopLoadMore, setIsStopLoadMore] = useState<boolean>(false)

  const [requestFrom, setRequestFrom] = useState<InitialStateType>({
    date: undefined as any,
    identify: Header.TYPE_FROM_DATE,
  })
  const [requestTo, setRequestTo] = useState<InitialStateType>({
    date: undefined as any,
    identify: Header.TYPE_TO_DATE,
  })
  // const [fileReportMngData, setFileReportMngData] = useState<any>(data?.length ? data : [])
  const [isDisable, setIsDisable] = useState<boolean>(true)

  // upload file
  const [percentage, setPercentage] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isCancel, setIsCancel] = useState(false)
  const [fileName, setFileName] = useState('')
  const [sourceTemp, setSourceTemp] = useState(axios.CancelToken.source())

  const [isPopupConfirmCreateCM, setIsPopupConfirmCreateCM] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  //call api
  const {
    data: dataRes,
    isLoading,
    fetchNextPage,
  } = useInfiniteQuery(
    'get_report_mng',
    ({ pageParam = 1 }) =>
      getAllFileReport({
        limit: LIMIT_PAGE,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1
        return nextPage
      },
    },
  )

  const fetchMoreData = () => {
    setTakePage(takePage + 1)
    if (!isStopLoadMore) {
      fetchNextPage()
    }
  }

  // handle upload
  const uploadProps = {
    action: BASE_URL + API_ENDPOINTS.upload.uploadFileReportData,
    accept: '*/*',
    multiple: false,
    beforeUpload(file: any) {
      setSourceTemp(axios.CancelToken.source())
      setIsUploading(true)
      setFileName(file.name)
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(file)
        }, 1000)
        setIsOpen(true)
      }) as any
    },
    onSuccess(response: any) {
      setIsOpen(false)
      setIsUploading(false)
      setPercentage(0)
      navigate('/report/preview', { state: { data: response?.data } })
    },
    onError(error: any) {
      setIsOpen(false)
      setIsUploading(false)
      setPercentage(0)
      const err = error?.response?.data
      if (err?.statusCode === STATUS_CODE.BAD_REQUEST) {
        if (err?.message === MESSAGE_RESPONSE.NEW_CARRIER_FOUND) {
          dispatch(setCountCarrierCommission())
          setIsPopupConfirmCreateCM(true)
        } else if (err?.message === MESSAGE_RESPONSE.FILE_EMPTY) {
          notify(MESSAGES.ERROR.E_FILE_EMPTY, STATUS.ERROR)
        } else if (err?.message === MESSAGE_RESPONSE.TEMPLATE_INVALID) {
          notify(MESSAGES.ERROR.TEMPLATE_INVALID, STATUS.ERROR)
        } else {
          notify(MESSAGES.ERROR.E_FILE_DUPLICATED, STATUS.ERROR)
        }
      } else if (err?.statusCode === STATUS_CODE.FORBIDDEN) {
        notify(MESSAGES.ERROR.E_INVALID_FILE, STATUS.ERROR)
      } else {
        !isCancel && notify(MESSAGES.ERROR.E_SOMETHING_WENT_WRONG, STATUS.ERROR)
      }
    },
    onProgress(step: any) {
      setPercentage(Math.round(step.percent))
      setTimeout(() => {
        if (Math.round(step.percent) === 100) {
          setIsOpen(false)
        }
      }, 1000)
    },
    customRequest({ action, data, file, filename, headers, onError, onProgress, onSuccess }: any) {
      //post form-data with 'axios'
      const formData = new FormData()
      if (data) {
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key])
        })
      }
      formData.append(filename, file)
      axios
        .post(action, formData, {
          headers,
          cancelToken: sourceTemp.token,
          onUploadProgress: ({ total, loaded }: any) => {
            onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file)
          },
        })
        .then(({ data: response }) => {
          onSuccess(response, file)
          navigate('/report/preview', { state: { data: response?.data } })
        })
        .catch(onError)
    },
  }

  const cancelCreateNewCarrier = () => {
    dispatch(checkCountCarrierCommission())
    dispatch(setCloseConfirmCreateCarrierCommission())
    setIsPopupConfirmCreateCM(false)
  }

  const handleCancelUploadFileReport = () => {
    sourceTemp.cancel()
    setIsOpen(false)
    setIsCancel(true)
  }

  // handle submit filter
  const onSetCallBackDateTime = (value: InitialStateType, identify: string) => {
    if (value !== null && value !== undefined) {
      if (identify === Header.TYPE_FROM_DATE) {
        setRequestFrom(value)
      } else {
        setRequestTo(value)
      }
    }
  }

  useEffect(() => {
    if (requestTo?.date !== undefined) {
      const dates = new Date()
      dates.setDate(requestTo?.date ? requestTo?.date?.getDate() - 30 : new Date().getDate())
      const dateString = new Date(dates.toISOString().split('T')[0])
      const lastDate = {
        date: dateString,
        identify: Header.TYPE_FROM_DATE,
      }
      setRequestFrom(lastDate as InitialStateType)
    }
  }, [requestTo])

  const LimitSelectToDate = () => {
    const dates = new Date()
    dates.setDate(requestTo?.date ? requestTo?.date?.getDate() - 30 : new Date().getDate())
    const dateString = new Date(dates.toISOString().split('T')[0])
    return dateString
  }

  const onHandleApplyFilterDate = () => {
    setIsLoadingData(false)
    // if (requestFrom?.date && requestTo?.date) {
    //   const result = data?.data.filter((element: any) => {
    //     return (
    //       requestFrom.date < new Date(`${element.createdAt}`) &&
    //       new Date(`${element.createdAt}`) < requestTo.date
    //     )
    //   })
    //   setFileReportMngData(result)
    // }
  }

  useEffect(() => {
    if (requestFrom.date && requestTo.date) {
      setIsDisable(true)
    } else {
      setIsDisable(false)
    }
  }, [requestFrom.date, requestTo.date])

  useEffect(() => {
    if (dataRes?.pages[takePage - 1]?.data?.length < LIMIT_PAGE) {
      setIsStopLoadMore(true)
    } else {
      setIsStopLoadMore(false)
    }
  }, [dataRes?.pages])

  return (
    <div className="w-full py-10 content-wrapper bg-neutral-8">
      {isPopupConfirmCreateCM && (
        <PopupBase>
          <div className="justify-center bg-neutral-8 w-[420px] h-auto rounded-xl p-6 border shadow-2xl">
            <div className="flex justify-between pb-6">
              <p></p>
              <div className="text-center text-headline5 text-neutral-1">New Carrier found</div>
              <IconCancel className="-mt-2 -mr-2 cursor-pointer" onClick={cancelCreateNewCarrier} />
            </div>
            <p className="text-center">
              Do you want to create New Carrier Commission before upload Report file?
            </p>
            <div className="flex justify-between gap-3 pt-6">
              <ButtonBase className="btn-outline w-[180px]" onClick={cancelCreateNewCarrier}>
                Cancel
              </ButtonBase>
              <ButtonBase
                className="btn-primary w-[180px]"
                onClick={() => {
                  dispatch(setCountCarrierCommission())
                  dispatch(setOpenConfirmCreateCarrierCommission())
                  navigate('/commission')
                }}
              >
                Create
              </ButtonBase>
            </div>
          </div>
        </PopupBase>
      )}

      <div className="flex items-center justify-between">
        <p className="text-headline4 bold text-neutral-1">Report management</p>
        {isOpen && (
          <PopupBase>
            <div className="justify-center bg-neutral-8 w-[420px] h-auto rounded-xl p-6 border shadow-2xl">
              <div className="pb-6 text-center text-headline5 text-neutral-1">
                Uploading and validating file
              </div>
              <p className="pb-4 font-semibold text-body1 text-neutral-3 ">
                {fileName.split('.')[0]}
              </p>
              <div className="progress-container">
                <Line
                  className="pb-4 rounded-md"
                  percent={percentage}
                  strokeWidth={3}
                  trailWidth={3}
                  trailColor="#EBDDFF"
                  strokeColor={isUploading ? '#6A41A6' : '#92ed14'}
                />
              </div>
              <div className="flex justify-between">
                <div className=" text-body2 text-neutral-4">
                  {isUploading ? `${percentage}% ` : `Finished`}
                </div>
                <ButtonBase
                  className="btn-outline btn-cancel-upload"
                  onClick={handleCancelUploadFileReport}
                >
                  Cancel
                </ButtonBase>
              </div>
            </div>
          </PopupBase>
        )}
        <Upload {...uploadProps}>
          <ButtonBase className="btn-primary btn-icon-prefix">
            <IconUpload />
            <p>Upload Report</p>
          </ButtonBase>
        </Upload>
      </div>
      <div className="flex items-center pt-5">
        <p className="mr-6 font-semibold">Show report from</p>
        <DateTimePicker
          setCallBackDateTime={onSetCallBackDateTime}
          identify={Header.TYPE_FROM_DATE}
          requestDateTime={requestFrom}
          setDateTime={setRequestFrom}
          minDate={LimitSelectToDate()}
          maxDate={!requestTo?.date ? new Date() : requestTo?.date}
          styles="mr-6"
        />
        <p className="mr-6 font-semibold">To</p>
        <DateTimePicker
          setCallBackDateTime={onSetCallBackDateTime}
          identify={Header.TYPE_TO_DATE}
          requestDateTime={requestTo}
          setDateTime={setRequestTo}
          minDate={requestFrom?.date ? requestFrom?.date : undefined}
          maxDate={new Date()}
          styles="mr-6"
        />
        <ButtonBase disabled={!isDisable} className="btn-outline" onClick={onHandleApplyFilterDate}>
          Apply
        </ButtonBase>
      </div>
      <div className="flex items-center justify-start pt-5">
        {/* table */}
        <div className="relative w-full overflow-auto border rounded-t-2xl rounded-b-2xl">
          <table className="w-full mb-5 text-left">
            <thead className="bg-primary-shade4 text-body1 text-neutral-2 md:pr-4">
              <tr>
                {tableHeader.slice(localStorage ? 0 : 1, tableHeader.length).map((item, index) => (
                  <th key={index} className={`font-semibold py-5 pl-6 ${item.styles}`}>
                    <span className={`${item.isOneLineRes && 'lg:mt-[-3.5px]'}`}>{item.name}</span>
                  </th>
                ))}
              </tr>
            </thead>
          </table>
          {isLoading || isLoadingData ? (
            <Loading className="relative py-6" height={30} width={30} />
          ) : (
            <InfiniteScroll
              dataLength={0}
              next={fetchMoreData}
              hasMore={true}
              height={'max-content'}
              style={{ maxHeight: 644 }}
              loader={''}
              endMessage={''}
              className="cus-scrollbar"
            >
              <table className="w-full mb-3 cursor-pointer">
                <tbody>
                  {dataRes?.pages?.map((page, index) => (
                    <React.Fragment key={index}>
                      {page?.data?.map((item: any, idx: number) => (
                        <tr key={idx} className="h-[72px] w-full hover:bg-neutral-7 group">
                          <td className="w-[50%] py-5 pl-6 text-body2 break-all group-hover:text-primary-shade2 group-hover:underline group-hover:underline-offset-4">
                            <span>{item?.fileName}</span>
                          </td>
                          <td
                            className={`w-[25%] py-5 pl-6 text-body2 break-all ${'md:pl-[20px]'}`}
                          >
                            <span>
                              {<Moment format="MM/DD/yyyy - HH:mm">{item?.createdAt ?? ''}</Moment>}
                            </span>
                          </td>
                          <td
                            className={`w-[25%] pr-2 py-5 pl-8 break-all text-body2 text-secondary-2 group-hover:underline group-hover:underline-offset-4 ${'md:pr-5 md:pl-[10px]'}`}
                          >
                            {item?.totalUnmatch && (
                              <span>
                                {item?.totalUnmatch}
                                {HandleChangeString(item?.totalUnmatch, ' unmatched bond')}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
              {!dataRes?.pages[0]?.length && (
                <p className="pb-6 text-center text-body1 text-neutral-4">No data available</p>
              )}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReportManagement

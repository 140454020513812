import React from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
import loadable from '@loadable/component'
import AgencyCreate from '../../pages/commission/containers/AgencyCreate'
import AgencyEdit from '../../pages/commission/containers/AgencyEdit'
import CarrierCustomizeEdit from '../../pages/commission/containers/CarrierCustomizeEdit'
import CarrierDetail from '../../pages/commission/containers/CarrierDetail'
import CarrierEdit from '../../pages/commission/containers/CarrierEdit'
import CarrierListEdit from '../../pages/commission/containers/CarrierListEdit'

const PageNotFound = loadable(() => import('../../components/layouts/PageNotFound'))
const Page = loadable(() => import('../../pages'))
const Home = loadable(() => import('../../pages/home'))
const BondEdit = loadable(() => import('../../pages/bond-edit'))
const Commission = loadable(() => import('../../pages/commission'))
const Invoice = loadable(() => import('../../pages/invoice'))
const OutputFile = loadable(() => import('../../pages/output-file'))
const Report = loadable(() => import('../../pages/report'))
const Transactions = loadable(() => import('../../pages/transactions'))
const ReportUploadPreview = loadable(() => import('../../pages/report-upload-preview'))

const NeedToLogin = () => {
  return (
    <div>
      <span className="text-5xl">You need to login now!</span>
    </div>
  )
}

const ProtectedRoute = ({ isAllowed = false, children = null }: any) => {
  if (!isAllowed) {
    return <NeedToLogin />
  }

  return children ? children : <Outlet />
}

const user = { id: 'a', name: 'robin', permissions: ['analyze'], roles: ['admin'] }

const RouterOutlet = () => {
  return (
    <Routes>
      <Route element={<Page />}>
        <Route element={<ProtectedRoute isAllowed={!!user} />}>
          <Route index element={<Home />} />
          <Route path="report" element={<Report />} />
          <Route path="bond-edit" element={<BondEdit />} />
          <Route path="invoice" element={<Invoice />} />
          <Route path="commission" element={<Commission />} />
          <Route path="commission/agency" element={<AgencyEdit />} />
          <Route path="commission/agency/create" element={<AgencyCreate />} />
          <Route path="commission/customize/edit" element={<CarrierCustomizeEdit />} />
          <Route path="commission/carrier/:carrierId" element={<CarrierDetail />} />
          <Route path="commission/carrier/:carrierId/edit" element={<CarrierEdit />} />
          <Route path="commission/carrier/edit" element={<CarrierListEdit />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="output-file" element={<OutputFile />} />
          <Route path="report/preview" element={<ReportUploadPreview />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  )
}

export default RouterOutlet

/**
 * Handle change string add text 's' or no
 * @param number  to check > or < 1
 * @param text    text need to update
 * @returns       return updated text
 */
export const HandleChangeString = (number: number, text: string) => {
  if (number > 1) {
    return `${text}s`
  }
  return text
}

export const STATUS_CODE = {
  FORBIDDEN: 403,
  BAD_REQUEST: 400,
}

export const MESSAGE_RESPONSE = {
  FILE_EMPTY: 'FILE_EMPTY',
  NEW_CARRIER_FOUND: 'NEW_CARRIER_FOUND',
  ALL_DATA_DUPLICATED: 'ALL_DATA_DUPLICATED',
  TEMPLATE_INVALID: 'TEMPLATE_INVALID',
}

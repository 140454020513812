import React, { FC } from 'react'

type Props = {
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  children?: React.ReactNode
  className?: string
  disabled?: boolean
}

const ButtonBase: FC<Props> = ({
  children,
  className = 'btn-primry',
  type = 'button',
  onClick,
  disabled = false,
}) => {
  return (
    <button type={type} onClick={onClick} disabled={disabled} className={`btn ${className}`}>
      {children}
    </button>
  )
}

export default ButtonBase

export const MESSAGES = {
  SUCCESS: {
    S_SUCCESS: 'The message success',
    S_CREATE_NEW_CARRIER_COMMISSION: 'The carrier has been added successfully.',
    S_CARRIER_COMMISSION_EDIT: 'The edit has been saved successfully.',
    S_CARRIER_COMMISSION_DETAIL_EDIT: 'The table has been edited successfully.',
  },
  WARNING: {
    W_WARNING: 'The message warning',
  },
  ERROR: {
    E_REQUIRED: 'This input is compulsory.',
    E_ERROR: 'The message error',
    E_SOMETHING_WENT_WRONG: 'Something went wrong. Please try again later.',
    E_INVALID_FILE: 'The file needs to be in .xlsx format to be uploaded. Please try again!',
    E_NEW_CARRIER_FOUND: 'NEW_CARRIER_FOUND',
    E_MAX_LENGTH: 'Carrier Name can’t be longer than 200 characters.',
    E_CARRIER_NAME: 'Please fill in alphabetical letters, and numbers only.',
    E_COMMISSION_PERCENT: 'Please fill in a percentage or N/A.',
    E_CATEGORIZING_STRINGS: 'Please fill in a string or N/A.',
    E_CREATE_CARRIER_DUPLICATE: 'The Carrier existed. Please fill in a new one.',
    E_CREATE_CARRIER_DUPLICATE_KEY: 'The Carrier Key existed. Please fill in a new one.',
    E_CREATE_CARRIER_DUPLICATE_NAME: 'The Carrier Name existed. Please fill in a new one.',
    E_FILE_EMPTY: 'The report file contains no data. Please check and try again.',
    TEMPLATE_INVALID: 'The report’s data structure is incorrect. Please check and try again.',
    E_FILE_DUPLICATED: 'The report file is duplicated. Please check and try again.',
  },
  INFO: {
    I_INFO: 'The message info',
  },
}

export enum STATUS {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

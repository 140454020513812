export const tableHeader = [
  {
    styles: 'w-[50%] ',
    name: 'File Report name',
    label: 'File Report name',
    isOneLineRes: true,
  },
  {
    styles: 'w-[25%] ',
    name: 'Date upload',
    label: 'Date upload',
    isOneLineRes: true,
  },
  {
    styles: 'w-[25%] ',
    name: 'Recoincile status',
    label: 'Recoincile status',
    isOneLineRes: true,
  },
]

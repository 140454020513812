export class Header {
  private _isFilter!: boolean
  public static readonly TYPE_RESPONSE_LABEL: string = 'response'
  public static readonly TYPE_FROM_DATE: string = 'from'
  public static readonly TYPE_TO_DATE: string = 'to'
  public static readonly TYPE_EMPTY_DATE: string = ''

  /**
   *
   * @param id
   * @param name
   * @param label
   * @param isFilter
   * @param minWidth
   */
  constructor(id: number, name: string, label: string, isFilter: boolean, minWidth: string) {
    this._isFilter = isFilter
  }

  public get isFilter(): boolean {
    return this._isFilter
  }

  /**
   * Header no scroll with item < 10
   * @returns
   */
  static showHeaderNoScrollDeveloper() {
    return [new Header(2, 'Date / time', 'date_time', true, 'w-[150px] min-w-[55px]')]
  }

  /**
   * Header scroll with item >= 10
   * @returns
   */
  static showHeaderWithScrollDeveloper() {
    return [new Header(2, 'Date / time', 'date_time', true, 'w-[150px] min-w-[55px] md:mr-2')]
  }

  /**
   *
   * @returns
   */
  static showHeaderNoScrollAdmin() {
    return [new Header(3, 'Date / time', 'date_time', true, 'w-[152px] min-w-[76px]')]
  }

  /**
   * Header scroll with item >= 10
   * @returns
   */
  static showHeaderWithScrollAdmin() {
    return [new Header(3, 'Date / time', 'date_time', true, 'w-[152px] min-w-[76px] md:mr-2')]
  }
}

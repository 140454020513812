//USER
export const SET_USERS_DATA = 'SET_USERS_DATA'
export const CLEAR_USERS_DATA = 'CLEAR_USERS_DATA'

//FILE REPORT MANAGEMENT
export const SET_FILE_REPORT_DATA = 'SET_FILE_REPORT_DATA'
export const CLEAR_FILE_REPORT_DATA = 'CLEAR_FILE_REPORT_DATA'
export const UPLOAD_FILE_REPORT_DATA = 'UPLOAD_FILE_REPORT_DATA'

//COMMISSION
export const SET_OPEN_POPUP_CONFIRM_CREATE_CARRIER_COMMISSION =
  'SET_OPEN_POPUP_CONFIRM_CREATE_CARRIER_COMMISSION'
export const SET_CLOSE_POPUP_CONFIRM_CREATE_CARRIER_COMMISSION =
  'SET_CLOSE_POPUP_CONFIRM_CREATE_CARRIER_COMMISSION'
export const SET_COUNT_NEW_CARRIER_COMMISSION = 'SET_COUNT_NEW_CARRIER_COMMISSION'
export const CHECK_COUNT_NEW_CARRIER_COMMISSION = 'CHECK_COUNT_NEW_CARRIER_COMMISSION'

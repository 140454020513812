import React, { FC, forwardRef } from 'react'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

export type InputType = 'text' | 'email' | 'number' | 'password'

export type InputProps = {
  id?: string
  name?: string
  label?: string
  placeholder?: string
  isError?: boolean
  type?: InputType
  className?: string
  value?: string | number
  defaultValue?: string | number
  handleChange?: (val: string | any) => void
  maxLength?: number
  pattern?: string
  onFocus?: () => void
  onBlur?: any
  autoFocus?: boolean
}

// Using maps so that the full Tailwind classes can be seen for purging
// see https://tailwindcss.com/docs/optimizing-for-production#writing-purgeable-html
const InputBase: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      autoFocus,
      id,
      name,
      label,
      isError = false,
      type = 'text',
      className = '',
      placeholder,
      value,
      defaultValue,
      pattern = '',
      maxLength,
      handleChange = () => null,
      onFocus,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const inputClasses = classNames(
      twMerge(
        `relative text-neutral-2 p-3 text-base 
        ${!isError && 'border-neutral-6'} 
        ${className}`,
      ),
      `focus:border-current focus:ring-0 relative inline-flex appearance-none border-2 font-bold placeholder-neutral-5 placeholder:font-medium focus:placeholder-neutral-5 transition-colors ease-in-out rounded-xl h-[52px] px-4 leading-tight focus:outline-none focus:ring-neutral-5 focus:shadow-outline`,
      className,
    )
    return (
      <input
        id={id}
        ref={ref}
        name={name}
        type={type}
        pattern={pattern}
        maxLength={maxLength}
        aria-label={label}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          handleChange(pattern ? e : e.currentTarget.value)
        }
        onFocus={onFocus}
        onBlur={onBlur}
        className={inputClasses}
        autoFocus={autoFocus}
        {...props}
      />
    )
  },
)
export default InputBase

import { useQuery } from 'react-query'
import { BASE_URL } from '../constants/urls'
import axiosService from './axiosServices'
import { API_ENDPOINTS } from './urlApi'

export const getCarrierCommissions = async () => {
  const response: any = await axiosService.getAll(
    {},
    `${BASE_URL}${API_ENDPOINTS.commissions.getCarrier}`,
  )
  return response?.data?.data
}

const urlGetCarrierCommissionById = BASE_URL + API_ENDPOINTS.commissions.getCarrierCommissionById

export const getCarrierCommissionById = async (id: string) => {
  const response: any = await axiosService.getById(id, urlGetCarrierCommissionById)
  return response
}

export const getCommissionById = (id: string) => {
  return useQuery(['getCarrierCommissionsById'], async () => {
    const { data }: any = await axiosService.getById(id, urlGetCarrierCommissionById)
    return data?.data
  })
}

export const editCarrierCommission = async (dataReq: object, id: string) => {
  const url = BASE_URL + API_ENDPOINTS.commissions.editCarrierCommission + id
  return await axiosService.update(dataReq, url)
}

export const createCarrierCommissions = async (dataRes: object) => {
  const url = BASE_URL + API_ENDPOINTS.commissions.createCarrierCommission
  return await axiosService.post(JSON.stringify(dataRes) as any, url)
}

export const editCarrierCommissions = async (dataForm: any) => {
  const response: any = await axiosService.put(
    { carrierCommissions: dataForm },
    `${BASE_URL}${API_ENDPOINTS.commissions.getCarrier}`,
  )
  return response?.data?.data
}

export const getAgencyCommissionPlans = async () => {
  const response: any = await axiosService.getAll(
    {},
    `${BASE_URL}${API_ENDPOINTS.commissions.getAgencyCommissionPlans}`,
  )
  return response?.data?.data
}

import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { createPortal } from 'react-dom'

import IconClose from '../../assets/icons/close_black.svg'

enum DIALOG_TYPES {
  CONFIRM = 'confirm_dlg',
  MESSAGE = 'msg_dlg',
}

interface DialogData {
  type: DIALOG_TYPES
  className?: {
    dlg?: string
    actions?: string
    btnYes?: string
    btnClose?: string
    btnCancel?: string
  }
  disableClose?: boolean
  data?: {
    header?: string
    content?: string | any
    close?: string
    yes?: string
    cancel?: string
  }
  afterClosed?: (res?: boolean, data?: any) => void
}

// Common dialog Context
const DialogContext = React.createContext({
  setDialog: (data: DialogData) => data,
})

// Create `useDialog` hook that using DialogContext
const useDialog = () => {
  const context = React.useContext(DialogContext)
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider')
  }
  return context
}

// Dialog Component
const Dialog = ({ dialog = {} as DialogData, onClosed = () => {} }) => {
  // Get and set default value to Dialog props
  const { data, className, type, afterClosed, disableClose }: DialogData = {
    className: {},
    data: {},
    disableClose: false,
    afterClosed: () => null,
    ...dialog,
  }

  const dialogType = () => {
    switch (type) {
      case DIALOG_TYPES.CONFIRM:
        return (
          <>
            <div className="py-6 text-center">{data.content}</div>
            <div className="flex justify-between">
              <button className="btn dlg-btn btn-outline" onClick={() => onCloseDialog(false)}>
                {data.close || 'Close'}
              </button>
              <button className="btn btn-primary dlg-btn" onClick={() => onCloseDialog(true)}>
                {data.yes || 'Yes'}
              </button>
            </div>
          </>
        )

      case DIALOG_TYPES.MESSAGE:
        return (
          <>
            <div className="py-6 text-center">{data.content}</div>
            <div className="flex justify-end">
              <button className="btn btn-primary dlg-btn" onClick={() => onCloseDialog(false)}>
                {data.close || 'Close'}
              </button>
            </div>
          </>
        )

      default:
        return null
    }
  }

  const onCloseDialog = (value = false) => {
    // Close dialog on DialogProvider
    onClosed()

    // Excuse after close callback function on Component
    if (afterClosed) {
      afterClosed(value)
    }
  }

  // Prevent body scroll
  document.body.classList.add('prevent-scroll')

  return createPortal(
    <div className="dlg-container">
      <div className="dlg-overlay" onClick={() => !disableClose && onCloseDialog(false)}>
        <div className={`dlg ${className.dlg}`} onClick={(e) => e.stopPropagation()}>
          <div className="dlg-wrapper">
            <div className="flex relative justify-center">
              <p className="text-headline4 text-neutral-1">{data.header}</p>
              <button className="absolute -top-3 -right-3" onClick={() => onCloseDialog(false)}>
                <img src={IconClose} alt="Close" />
              </button>
            </div>
            {dialogType()}
          </div>
        </div>
      </div>
    </div>,
    document.body,
  )
}

// DialogProvider: Provide Dialog on App
const DialogProvider = (props: any) => {
  // Manage Dialog as a State
  const [dialog, setDialog] = useState(null)
  const location = useLocation()

  // Watch onClosed Event on Dialog Component
  const onClosed = useCallback(() => {
    // Set null for DialogContext, that mean close Dialog Component on Provider
    setDialog(null)

    // Remove prevent scroll
    document.body.classList.remove('prevent-scroll')
  }, [setDialog])

  // Close dialog if location is changed
  useEffect(() => {
    onClosed()
  }, [location])

  // Remove prevent scroll
  useEffect(() => {
    if (!dialog) {
      document.body.classList.remove('prevent-scroll')
    }
  }, [dialog])

  return (
    <DialogContext.Provider value={{ setDialog, onClosed }} {...props}>
      {props.children}
      <Suspense fallback={<></>}>
        {dialog && <Dialog dialog={dialog} onClosed={onClosed} />}
      </Suspense>
    </DialogContext.Provider>
  )
}

export { DialogProvider, useDialog, DIALOG_TYPES }

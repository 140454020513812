import React from 'react'
import ReactLoading from 'react-loading'

interface LoadingProps {
  className?: string
  height?: number
  width?: number
}
const Loading: React.FC<LoadingProps> = ({
  className = 'absolute top-[50%] left-[50%]',
  height = 50,
  width = 50,
}) => {
  return (
    <div className={className}>
      <ReactLoading className="mx-auto" type="spin" color="#A989D8" height={height} width={width} />
    </div>
  )
}

export default Loading

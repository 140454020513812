export enum STATUS {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export type Option = {
  id: number | string
  name: string
  value?: string
}

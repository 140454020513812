import { combineReducers } from 'redux'
import userReducers from './userReducers'
import reportReducer from './reportReducer'

const rootReducers = combineReducers({
  userReducers,
  reportReducer,
})

export default rootReducers

import { MESSAGES } from '../constants/messages'

export const namePattern = {
  value: new RegExp(/^[a-zA-Z0-9 ]+$/i, 'ig'),
  message: MESSAGES.ERROR.E_CARRIER_NAME,
}

export const commissionPercentPattern = {
  value: new RegExp(/^((N\/A|n\/a)*|([0-9]*\.[0-9]+|[0-9]+))$/i, 'ig'),
  message: MESSAGES.ERROR.E_COMMISSION_PERCENT,
}

export const commissionPercentPatternNumber = {
  value: new RegExp(/^([0-9]*\.[0-9]+|[0-9]+)$/i, 'ig'),
  message: MESSAGES.ERROR.E_COMMISSION_PERCENT,
}

export const categorizingStringsPattern = {
  value: new RegExp(/^[a-zA-Z0-9 ]+^(N\/A|n\/a)$/i, 'ig'),
  message: MESSAGES.ERROR.E_CATEGORIZING_STRINGS,
}

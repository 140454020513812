import React, { useState } from 'react'
import DatePicker from 'react-date-picker'
import iconCalendar from '../../assets/images/icon-calendar.svg'
import { initialState, InitialStateType } from '../../pages/report'
import { Header } from '../../pages/report/component/header'

type Option = {
  id: number | string
  name: string
}

interface Props {
  setCallBackDateTime?: (value: InitialStateType, identify: string) => void
  identify?: string
  requestDateTime?: InitialStateType
  minDate?: Date
  maxDate?: Date
  setDateTime?: any
  styles?: string
}

const DateTimePicker: React.FC<Props> = ({
  identify = Header.TYPE_EMPTY_DATE,
  requestDateTime,
  setCallBackDateTime = () => initialState,
  setDateTime = () => null,
  minDate,
  maxDate,
  styles = '',
}) => {
  const [value, setValue] = useState<Date | null>(requestDateTime?.date as any)

  /**
   *
   * @param val
   * @param name
   */
  const getValue = (val: Option, name?: string) => {
    setCallBackDateTime(
      {
        date: value as any,
      },
      identify,
    )
  }

  /**
   *
   * @param event
   */
  const onChange = (event: Date) => {
    setValue(event)
    setDateTime(event)
    setCallBackDateTime(
      {
        date: event,
      },
      identify,
    )
  }

  return (
    <div>
      <DatePicker
        onChange={onChange}
        value={requestDateTime?.date as Date}
        defaultValue={requestDateTime?.date as Date}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        format={'MM/dd/yyyy'}
        className={`w-[240px] h-[52px] rounded-xl text-body font-semibold text-neutral-3 ${styles}`}
        calendarIcon={<img src={iconCalendar} width={24} height={24} />}
        clearIcon={null}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  )
}

export default DateTimePicker

import React from 'react'
import ReactDOM from 'react-dom'

const PopupBase = ({ children = <></>, id = 'root-portal', el = 'div' }) => {
  const [container] = React.useState(() => {
    // This will be executed only on the initial render
    // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    return document.createElement(el)
  })

  React.useEffect(() => {
    container.setAttribute('id', id)
    document.body.appendChild(container)

    return () => {
      document.body.removeChild(container)
    }
  }, [])

  return ReactDOM.createPortal(children, container)
}

export default PopupBase

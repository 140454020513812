import {
  SET_FILE_REPORT_DATA,
  CLEAR_FILE_REPORT_DATA,
  UPLOAD_FILE_REPORT_DATA,
  SET_OPEN_POPUP_CONFIRM_CREATE_CARRIER_COMMISSION,
  SET_CLOSE_POPUP_CONFIRM_CREATE_CARRIER_COMMISSION,
  SET_COUNT_NEW_CARRIER_COMMISSION,
  CHECK_COUNT_NEW_CARRIER_COMMISSION,
} from './actionTypes'

export const setFileReportsData = (payload: string | object) => {
  return {
    type: SET_FILE_REPORT_DATA,
    payload: payload,
  }
}

export const clearFileReportsData = () => {
  return {
    type: CLEAR_FILE_REPORT_DATA,
  }
}

export const uploadFileReportsData = (payload: string | object) => {
  return {
    type: UPLOAD_FILE_REPORT_DATA,
    payload: payload,
  }
}

export const setOpenConfirmCreateCarrierCommission = () => {
  return {
    type: SET_OPEN_POPUP_CONFIRM_CREATE_CARRIER_COMMISSION,
  }
}

export const setCloseConfirmCreateCarrierCommission = () => {
  return {
    type: SET_CLOSE_POPUP_CONFIRM_CREATE_CARRIER_COMMISSION,
  }
}
export const setCountCarrierCommission = () => {
  return {
    type: SET_COUNT_NEW_CARRIER_COMMISSION,
  }
}
export const checkCountCarrierCommission = () => {
  return {
    type: CHECK_COUNT_NEW_CARRIER_COMMISSION,
  }
}

export type Option = {
  id: number | string
  name: string
  value?: string
}

export const LIMIT_PAGE = 50

export const titleTableCarrier = [
  { id: 'instantIssuePercent', title: 'Instant Issue' },
  { id: 'standardPercent', title: 'Standard' },
  { id: 'otherPercent', title: 'Other' },
  { id: 'contractPercent', title: 'Contract (Fast Pass)' },
  { id: 'accountCommercialPercent', title: 'Account (Commercial)' },
  { id: 'accountContractPercent', title: 'Account (Contract)' },
]

const VERSION = {
  v1: 'v1',
  v2: 'v2',
}
const RESOURCES = {
  posts: `api/${VERSION.v1}/posts`,
  upload: `api/${VERSION.v1}/upload`,
  report: `api/${VERSION.v1}/report`,
  user: `api/${VERSION.v1}/users`,
  commissions: `api/${VERSION.v1}/commissions`,
}
export const API_ENDPOINTS = {
  users: {
    getUserById: RESOURCES.user,
    getUsers: RESOURCES.user,
  },
  posts: {
    getPostById: RESOURCES.posts,
  },
  upload: {
    uploadFileReportData: `${RESOURCES.upload}/file`,
    uploadRecord: `${RESOURCES.upload}/upload-record`,
  },
  report: {
    getFileReportData: RESOURCES.report,
    edit: `${RESOURCES.report}/edit`,
  },
  commissions: {
    createCarrierCommission: `${RESOURCES.commissions}/carrier-commissions`,
    getCarrier: `${RESOURCES.commissions}/carrier-commissions`,
    getCarrierCommissionById: `${RESOURCES.commissions}/carrier-commissions/`,
    editCarrierCommission: `${RESOURCES.commissions}/carrier-commissions/`,
    getAgencyCommissionPlans: `${RESOURCES.commissions}/agency-commission-plans`,
  },
}
